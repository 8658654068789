import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { TConnectedApp } from "types";
import { getUserConnections } from "./api";

export const initialState = {
  connections: [] as TConnectedApp[],
} as const;

export const getServiceConnections = createAsyncThunk(
  "serviceConnections/getServiceConnections",
  async () => {
    const conns = await getUserConnections();
    return Array.isArray(conns) ? conns : [conns];
  }
);

export const serviceConnections = createSlice({
  name: "serviceConnections",
  initialState,
  reducers: {
    addConnection: (state, action: PayloadAction<TConnectedApp>) => {
      state.connections.push(action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(getServiceConnections.fulfilled, (state, action) => {
      state.connections = action.payload;
    });
  },
});

export const { addConnection } = serviceConnections.actions;

export default serviceConnections.reducer;
