export enum EFirestoreTables {
  Users = "users",
  Notifications = "notifications",
  UserServiceProfiles = "services",
  Friends = "friends",
  ConnectedApps = "userConnectedApps",
}

export enum EFriendStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",
}

export enum ENotificationTypes {
  FRIEND_REQUESTED,
  FRIEND_CONFIRMED,
  CRM_SUBSCRIBED,
}

export enum EGameServices {
  STEAM = "Steam",
  DISCORD = "Discord",
  UPLAY = "UPlay",
  EPIC = "Epic",
  PSN = "PSN",
  XBOX = "XBox",
  BATTLENET = "BattleNet",
  ORIGIN = "Origin",
  TWITCH = "Twitch",
  TWITTER = "Twitter",
  FACEBOOK = "Facebook",
  SNAPCHAT = "Snapchat",
  TIKTOK = "TikTok",
  KIK = "Kik",
  ONLYFANS = "Onlyfans",
  PATREON = "Patreon",
  PAYPAL = "Paypal",
}

export enum EErrors {
  MissingUser = "MissingUser",
  MissingUserService = "MissingUserService",
}

export type TEmail = `${string}@${string}.${string}`;

export type TUser = {
  uid: string;
  email: TEmail | undefined;
  displayPicture: string | undefined;
  displayName: string;
  description: string | null;
  /** Vanity URL */
  slug: string | null;
  /** Is this account allowed for alpha */
  active: boolean;
  /** Is this profile visible to non-friends */
  isPublic: boolean;
  isEmailConfirmed: boolean;
  isTest?: boolean;
  profileCompletion: {
    displayName: boolean;
    displayPicture: boolean;
    linkedService: boolean;
  };
};

export type TUserFriend = {
  from: string;
  to: string;
  status: EFriendStatus;
  createdAt: string;
};

export type TUserNotification = {
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
  meta: unknown;
  read: boolean;
  type: ENotificationTypes;
  uid: string;
  "uid#read": `${string}#${boolean}`;
};

export type TUserNotificationSerializable = Omit<
  TUserNotification,
  "createdAt"
> & {
  createdAtSerializable: number;
};

export type TUserService = {
  uid: string;
  serviceName: string;
  url: string;
  verified: boolean;
  createdAt: string;
  /** Is this visible publicly */
  public: boolean;
  /** Is this still profile is still active */
  active: boolean;
  order: number;
  "uid#public": `${string}#${boolean}`;
};

export type TConnectedApp = {
  uid: string;
  service: EGameServices;
  token: string;
  issuer: string;
  subject: string;
  audience: string;
  expiration: number;
  issuedAt: number;
  nonce: string;
  displayName?: string;
};

export type TSlug = {
  uid: string;
  email: string;
};

export type TFeedback = {
  name: string;
  email: TEmail;
  body: string;
};

export type TState = "idle" | "working" | "success";

export type TTranslations = {
  [x: string]: string;
};
