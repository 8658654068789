import {
  getFirestore,
  setDoc,
  collection,
  doc,
  query,
  where,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { EGameServices, EFirestoreTables, TConnectedApp, EErrors } from "types";
import { currentUser, db } from "utils/firebase";

export const createUserConnection = async ({
  service,
  token,
  issuer,
  subject,
  audience,
  expiration,
  issuedAt,
  nonce,
  displayName,
}: {
  service: EGameServices;
  token: string;
  issuer: string;
  subject: string;
  audience: string;
  expiration: number;
  issuedAt: number;
  nonce: string;
  displayName?: string;
}) => {
  const user = currentUser();
  const docRef = doc<TConnectedApp>(
    collection(db, EFirestoreTables.ConnectedApps) as any,
    `${user.uid}_${service}`
  );

  setDoc(docRef, {
    uid: user.uid,
    token,
    service,
    issuer,
    subject,
    audience,
    expiration,
    issuedAt,
    nonce,
    displayName,
  });
};

export const getUserConnections = async ({
  service,
  uid: refUser,
}: {
  service?: EGameServices;
  uid?: string;
} = {}) => {
  const uid = refUser || currentUser()?.uid;
  if (!uid) throw new Error(EErrors.MissingUser);

  const connCollection = collection(db, EFirestoreTables.ConnectedApps);
  if (service) {
    const docSnap = await getDoc(
      doc<TConnectedApp>(
        db as any,
        EFirestoreTables.ConnectedApps,
        `${uid}_${service}`
      )
    );
    return docSnap.data();
  } else {
    const q = query<TConnectedApp>(
      connCollection as any,
      where("uid", "==", uid)
    );

    const docs = await getDocs(q);
    const output: (TConnectedApp & { id: string })[] = [];
    docs.forEach(doc => output.push({ id: doc.id, ...doc.data() }));
    return output;
  }
};
